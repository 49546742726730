export const ROUTE_PREFIX = '/organization/:organization_id';
export const ROUTE_POSTFIX = '?mode=:mode_id';

// public routes
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_LOGIN = '/login';
export const ROUTE_PASSWORD_RESET = '/password_reset';
export const ROUTE_ONBOARDING = '/onboarding/invite/:invite_id';
export const ROUTE_ACCESS_DENIED = '/access-denied';

// common routes
export const ROUTE_SETTINGS = `${ROUTE_PREFIX}/settings${ROUTE_POSTFIX}`;
export const ROUTE_ORGANIZATION_SETTINGS = `${ROUTE_PREFIX}/organization-settings${ROUTE_POSTFIX}`;
export const ROUTE_PROFILE = `${ROUTE_PREFIX}/profile${ROUTE_POSTFIX}`;
export const ROUTE_NOTIFICATION_COMMENTS = `${ROUTE_PREFIX}/notifications/:notification_id/comments${ROUTE_POSTFIX}`;
export const ROUTE_PAGE_NOT_FOUND = '/page-not-found';

// echo routes
export const ROUTE_ECHO_ROOT = `${ROUTE_PREFIX}/echo`;
export const ROUTE_ECHO = `${ROUTE_ECHO_ROOT}/${ROUTE_POSTFIX}`;
export const ROUTE_ECHO_KNOWLEDGE = `${ROUTE_ECHO_ROOT}/knowledge${ROUTE_POSTFIX}`;
export const ROUTE_ECHO_METRICS = `${ROUTE_ECHO_ROOT}/metrics${ROUTE_POSTFIX}`;
export const ROUTE_ECHO_COLLECTIONS = `${ROUTE_ECHO_ROOT}/collections${ROUTE_POSTFIX}`;
export const ROUTE_ECHO_COLLECTION = `${ROUTE_ECHO_ROOT}/collections/:collection_id${ROUTE_POSTFIX}`;
export const ROUTE_ECHO_CUSTOMERS = `${ROUTE_ECHO_ROOT}/customers${ROUTE_POSTFIX}`;
export const ROUTE_ECHO_CUSTOMER = `${ROUTE_ECHO_ROOT}/customers/:customer_id${ROUTE_POSTFIX}`;
export const ROUTE_ECHO_EVENTS = `${ROUTE_ECHO_ROOT}/events${ROUTE_POSTFIX}`;
export const ROUTE_ECHO_EVENT = `${ROUTE_ECHO_ROOT}/:event_id${ROUTE_POSTFIX}`;
export const ROUTE_ECHO_EVENT_QUERIES = `${ROUTE_ECHO_ROOT}/queries${ROUTE_POSTFIX}`;
export const ROUTE_ECHO_TAGS = `${ROUTE_ECHO_ROOT}/tags${ROUTE_POSTFIX}`;
export const ECHO_COLLECTION = '/echo/collections/';

// fetch routes
export const ROUTE_FETCH_ROOT = `${ROUTE_PREFIX}/fetch`;
export const ROUTE_FETCH = `${ROUTE_FETCH_ROOT}${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_KNOWLEDGE_DOCUMENTS = `${ROUTE_FETCH_ROOT}/knowledge/documents${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_KNOWLEDGE_INTERACTIONS = `${ROUTE_FETCH_ROOT}/knowledge/interactions${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_KNOWLEDGE_CONTENT_CREATE = `${ROUTE_FETCH_ROOT}/knowledge/content/create${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_KNOWLEDGE_CONTENT_EDIT = `${ROUTE_FETCH_ROOT}/knowledge/content/:document_id/edit${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_METRICS_ANALYTICS = `${ROUTE_FETCH_ROOT}/metrics/analytics${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_METRICS_QUERIES = `${ROUTE_FETCH_ROOT}/metrics/queries${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_INTERACTIVE_METRICS_ANALYTICS = `${ROUTE_FETCH_ROOT}/interactive-metrics/analytics${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_COLLECTIONS = `${ROUTE_FETCH_ROOT}/collections${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_COLLECTION = `${ROUTE_FETCH_ROOT}/collections/:collection_id${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_COLLECTION_OVERVIEW = `${ROUTE_FETCH_ROOT}/collections/:collection_id/overview${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_COLLECTION_CRM = `${ROUTE_FETCH_ROOT}/collections/:collection_id/crm${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_COLLECTION_CONTENT = `${ROUTE_FETCH_ROOT}/collections/:collection_id/content/:content_id${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_COLLECTION_REPORT = `${ROUTE_FETCH_ROOT}/collections/:collection_id/reports/:report_id${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_COLLECTION_CONTENT_REFRESH = `${ROUTE_FETCH_ROOT}/collection-content/refresh${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_CHAT_ROOM = `${ROUTE_FETCH_ROOT}/chat-room${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_EVENTS = `${ROUTE_FETCH_ROOT}/events${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_EVENT_CATEGORY = `${ROUTE_FETCH_ROOT}/events/:category_id${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_EVENT_SUB_CATEGORY = `${ROUTE_FETCH_ROOT}/events/:category_id/sub/:sub_category_id${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_TAGS = `${ROUTE_FETCH_ROOT}/tags${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_KNOWLEDGE_GAP_REPORT = `${ROUTE_FETCH_ROOT}/knowledge-gap-report${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_CONVERSATION_SUMMARY = `${ROUTE_FETCH_ROOT}/conversation-summary${ROUTE_POSTFIX}`;
export const ROUTE_FETCH_CONVERSATIONS = `${ROUTE_FETCH_ROOT}/conversation-summary/:id/conversations${ROUTE_POSTFIX}`;

// live survey routes
export const ROUT_SURVEY_ROOT = `${ROUTE_PREFIX}/echo`;
export const ROUTE_LS_EVENTS = `${ROUT_SURVEY_ROOT}/ls/events${ROUTE_POSTFIX}`;
export const ROUTE_LS_EVENT_CATEGORY = `${ROUT_SURVEY_ROOT}/ls/events/:category_id${ROUTE_POSTFIX}`;
export const ROUTE_LS_EVENT_SUB_CATEGORY = `${ROUT_SURVEY_ROOT}/ls/events/:category_id/sub/:sub_category_id${ROUTE_POSTFIX}`;

//index routes
export const ROUTE_INDEX_ROOT = `${ROUTE_PREFIX}/index`;
export const ROUTE_INDEX_CHAT_ROOM = `${ROUTE_PREFIX}/index${ROUTE_POSTFIX}`;




