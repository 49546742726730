import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash';

import { CollectionI } from '../../../types/fetch/collections';
import { trackEvent } from '../../../utils/tracking';
import { DocumentProcessingStatusEnum } from '../../../enums/documents';

interface FetchCollectionsStateI {
    selectedCollection?: CollectionI,
    selectedContentId?: string,
    uploadingFiles: UploadingFile[];
};


interface UploadingFile {
    collectionId: string,
    fileName: string;
    fileId: string;
    status: DocumentProcessingStatusEnum.UPLOADING | DocumentProcessingStatusEnum.PROCESSING;
}

const initialState: FetchCollectionsStateI = {
    uploadingFiles: []
};

export const fetchCollectionsSlice = createSlice({
    name: 'fetch-collections',
    initialState,
    reducers: {
        setSelectedCollection: (state, action: PayloadAction<CollectionI | undefined>) => {
            state.selectedCollection = action.payload;
        },
        setSelectedContentId: (state, action: PayloadAction<string | undefined>) => {
            state.selectedContentId = action.payload;
        },
        clearFetchCollectionsState: (state) => {
            state.selectedCollection = undefined;
            state.selectedContentId = undefined;
        },
         addUploadingFile: (state, action: PayloadAction<UploadingFile>) => {
            const existingFileIndex = state.uploadingFiles.findIndex(file => file.fileId === action.payload.fileId && file.collectionId === action.payload.collectionId);

            if (existingFileIndex === -1) {
                state.uploadingFiles = [action.payload, ...state.uploadingFiles];

                trackEvent('Upload Document', {
                    fileId: action.payload.fileId,
                    fileName: action.payload.fileName,
                    status: action.payload.status
                });
            }
        },
        updateUploadingFile: (state, action: PayloadAction<{ fileId: string; collectionId: string; newFileId: string }>) => {
            const clonedUploadingFiles = cloneDeep(state.uploadingFiles);
            const indexOfFile: number = clonedUploadingFiles.findIndex(file => file.fileId === action.payload.fileId && file.collectionId === action.payload.collectionId);
            clonedUploadingFiles[indexOfFile].fileId = action.payload.newFileId
            clonedUploadingFiles[indexOfFile].status = DocumentProcessingStatusEnum.PROCESSING;
            state.uploadingFiles = clonedUploadingFiles;
        },
        removeUploadingFile: (state, action: PayloadAction<{ fileId: string, collectionId: string }>) => {
            state.uploadingFiles = state.uploadingFiles.filter(file => file.fileId !== action.payload.fileId && file.collectionId !== action.payload.collectionId);
        },
    },
})

export const { 
    setSelectedCollection, 
    setSelectedContentId, 
    clearFetchCollectionsState,
    addUploadingFile,
    updateUploadingFile,
    removeUploadingFile
} = fetchCollectionsSlice.actions;
export default fetchCollectionsSlice.reducer;