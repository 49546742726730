export enum DocumentVerificationStatusEnum {
    NOT_VERIFIED = 0,
    VERIFICATION_REQUESTED = 1,
    VERIFIED = 2
};

export enum DocumentProcessingStatusEnum {
    UPLOADING = 'uploading',
    PROCESSING = 'processing',
    FAILED = 'failed',
    COMPLETE = 'complete'
}

export enum DocumentTypeEnum {
    FILE = 'file',
    SENSO = 'senso',
    WEB = 'web',
    TIPTAP = 'tiptap'
}