import { useEffect, useMemo, lazy, Suspense, useState } from 'react';
import Modal from 'react-modal';
import styles from './AgentFetch.module.css';
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setCurrentModule } from '../../state/features/app/appSlice';
import { ModuleEnum } from '../../enums/app';
import { setIsNewSession } from '../../state/features/fetchChatRoom/fetchChatRoomSlice';
import { ROUTE_FETCH_CHAT_ROOM, ROUTE_FETCH_COLLECTION_CONTENT, ROUTE_PROFILE, ROUTE_FETCH_COLLECTION_CONTENT_REFRESH, ROUTE_FETCH_COLLECTION_OVERVIEW, ROUTE_FETCH_INTERACTIVE_METRICS_ANALYTICS, ROUTE_FETCH_ROOT, ROUTE_FETCH_COLLECTION_CRM, ROUTE_FETCH_COLLECTION_REPORT, ROUTE_FETCH_KNOWLEDGE_GAP_REPORT, ROUTE_ACCESS_DENIED, ROUTE_FETCH_CONVERSATION_SUMMARY, ROUTE_FETCH_CONVERSATIONS } from '../../constants/routes';
import useRoute from '../../hooks/useRoute';
import { getAmplifySessionHeaders } from '../../services/auth-service';
import { API_URL } from '../../config';
import { setIsFilterModalOpen } from '../../state/features/agentEcho/agentEchoSlice';
import FetchChatRoom from './components/FetchChatRoom/FetchChatRoom/FetchChatRoom';
import { CollectionPageModeEnum } from '../../enums/fetch/collections';
import { getRouteAfterRemovingPrefixAndPostfix } from '../../utils/routeHelpers';
import FetchFilters from "./components/FetchFilters/FetchFilters";
import { OrgUserRolesEnum } from '../../enums/organization';

const FetchConversations = lazy(() => import('./components/FetchConversationSummary/FetchConversations/FetchConversations'));
const FetchConversationSummary = lazy(() => import('./components/FetchConversationSummary/FetchConversationSummary'));
const FetchKnowledgeGapReport = lazy(() => import('./components/FetchKnowledgeGapReport/FetchKnowledgeGapReport'));
const FetchEvents = lazy(() => import('./components/FetchEvents/FetchEvents'));
const FetchEventCategory = lazy(() => import('./components/FetchEvents/FetchEventCategory/FetchEventCategory'));
const FetchEventSubCategory = lazy(() => import('./components/FetchEvents/FetchEventCategory/FetchEventSubCategory/FetchEventSubCategory'));
const FetchAnalytics = lazy(() => import('./components/FetchMetrics/FetchAnalytics/FetchAnalytics'));
const FetchQueries = lazy(() => import('./components/FetchMetrics/FetchQueries/FetchQueries'));
const FetchKnowledgeDocuments = lazy(() => import('./components/FetchKnowledge/FetchKnowledge/FetchKnowledgeDocuments'));
const FetchContentCreate = lazy(() => import('./components/FetchKnowledge/FetchKnowledge/FetchContent/FetchContentCreate/FetchContentCreate'));
const FetchContentEdit = lazy(() => import('./components/FetchKnowledge/FetchKnowledge/FetchContent/FetchContentEdit/FetchContentEdit'));
const FetchTags = lazy(() => import('./components/FetchKnowledge/FetchTags/FetchTags'));
const FetchCollections = lazy(() => import('./components/FetchCollections/FetchCollectionsOld'));
const FetchCollection = lazy(() => import('./components/FetchCollections/FetchCollection/FetchCollection'));
const AgentProfile = lazy(() => import('./components/AgentProfile/AgentProfile'));
const FetchSave = lazy(() => import('./components/FetchSave/FetchSave'));
const FetchKnowledgeInteractions = lazy(() => import('./components/FetchKnowledge/FetchKnowledge/FetchKnowledgeInteractions'));
const FetchInteractiveMetrics = lazy(() => import('./components/FetchInteractiveMetrics/FetchInteractiveMetrics'));

const AgentFetch = () => {
    const [selectedFilters, setSelectedFilters] = useState({
        sentiments: [],
        emotions: [],
        purposes: [],
        reps: [],
        startDate: getStartOfWeek(),
        endDate: getEndOfWeek()
    });

    const [filters, setFilters] = useState({
        sentiments: [],
        emotions: [],
        purposes: [],
        reps: []
    });
    const location = useLocation();
    const { getRoute } = useRoute();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedOrgId = useAppSelector((state) => state.organization.selectedOrganization?.org_id);
    const { isSaveModalOpen, isFilterModalOpen } = useAppSelector((state) => state.agentEcho);

    useEffect(() => {
        setSelectedFilters({
            sentiments: [],
            emotions: [],
            purposes: [],
            reps: [],
            startDate: getStartOfWeek(),
            endDate: getEndOfWeek()
        });
        setFilters({
            sentiments: [],
            emotions: [],
            purposes: [],
            reps: []
        });
        fetchFilters();
    }, [selectedOrgId]);

    useEffect(() => {
        dispatch(setCurrentModule(ModuleEnum.FETCH));
    }, [dispatch]);

    const isNewChatRoomSession = useAppSelector((state) => state.fetchChatRoom.isNewSession);
    const currentUser = useAppSelector((state) => state.user.currentUser);
    const isInOrgMode = useAppSelector((state) => state.user.isInOrgMode);
    const firstCollection = useAppSelector((state) => state.user.firstCollection);

    useEffect(() => {
        if (isNewChatRoomSession) {
            dispatch(setIsNewSession(false));
            navigate(getRoute(ROUTE_FETCH_CHAT_ROOM));
        }
    }, [isNewChatRoomSession]);

    const isInOrgModeAndNotMemberRoleUser = useMemo(() => {
        return isInOrgMode && currentUser && currentUser.current_org.role !== OrgUserRolesEnum.MEMBER;
    }, [isInOrgMode, currentUser]);

    async function fetchFilters() {
        try {
            const headers = await getAmplifySessionHeaders();
            const response = await fetch(`${API_URL}/interactions/filters/`, {
                method: 'POST', // Specify the method
                headers: {
                    ...headers,
                    'Content-Type': 'application/json' // Include the content type header
                }
            });
            if (response.ok) {
                const data = await response.json();
                setFilters(data);

                // Set all filters as selected by default
                const updatedSelectedFilters = { ...selectedFilters };
                Object.keys(data).forEach(category => {
                    updatedSelectedFilters[category] = data[category];
                });
                setSelectedFilters(updatedSelectedFilters);
            } else {
                console.error("Error fetching filter data:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching filter data:", error);
        }
    }

    function getStartOfWeek() {
        const date = new Date();
        date.setMonth(date.getMonth() - 3); // Set the date to three months ago
        return date.toISOString().split('T')[0];
    }

    function getEndOfWeek() {
        const date = new Date();
        date.setDate(date.getDate() + 1); // Set the date to tomorrow
        return date.toISOString().split('T')[0];
    }

    const hasCollectionViewPermissions = useMemo(() => {
        return currentUser?.ui_settings?.view_collections === true;
    }, [currentUser]);

    if (!currentUser) {
        return null;
    }

    return (
        <>
            <Modal isOpen={isFilterModalOpen} style={{ overlay: { zIndex: 10 }, content: { marginTop: '100px', borderRadius: '10px' } }}>
                <Suspense fallback={<></>}>
                    <FetchFilters
                        onClose={() => dispatch(setIsFilterModalOpen(false))}
                        filters={filters}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                    />
                </Suspense>
            </Modal>
            <Modal isOpen={isSaveModalOpen} style={{ overlay: { zIndex: 10 }, content: { marginTop: '100px', borderRadius: '10px' } }}>
                <Suspense fallback={<></>}>
                    <FetchSave selectedFilters={selectedFilters} />
                </Suspense>
            </Modal>
            <div className={styles.content}>
                <Routes>
                    {/*Import directly the Chat room page to avoid high loading time for the landing page of the application*/}
                    <Route path="/chat-room" element={
                        <FetchChatRoom key={location.key} />
                    } />
                    <Route path="/events" element={
                        isInOrgModeAndNotMemberRoleUser ?
                            <Suspense fallback={<></>}>
                                <FetchEvents key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_ACCESS_DENIED)} replace />
                    } />
                    <Route path="/events/:category_id" element={
                        isInOrgModeAndNotMemberRoleUser ?
                            <Suspense fallback={<></>}>
                                <FetchEventCategory />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_ACCESS_DENIED)} replace />
                    } />
                    <Route path="/events/:category_id/sub/:sub_category_id" element={
                        isInOrgModeAndNotMemberRoleUser ?
                            <Suspense fallback={<></>}>
                                <FetchEventSubCategory />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_ACCESS_DENIED)} replace />
                    } />
                    <Route path="/metrics/analytics" element={
                        isInOrgModeAndNotMemberRoleUser ?
                            <Suspense fallback={<></>}>
                                <FetchAnalytics key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_ACCESS_DENIED)} replace />
                    } />
                    <Route path="/metrics/queries" element={
                        isInOrgModeAndNotMemberRoleUser ?
                            <Suspense fallback={<></>}>
                                <FetchQueries key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_ACCESS_DENIED)} replace />
                    } />
                    <Route path="/knowledge/documents" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchKnowledgeDocuments key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge/interactions" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchKnowledgeInteractions key={location.key} selectedFilters={selectedFilters} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge/content/:document_id/edit" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchContentEdit key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge/content/create" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchContentCreate key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/tags" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchTags key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/collections" element={
                        hasCollectionViewPermissions ?
                            <Suspense fallback={<></>}>
                                <FetchCollections key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_FETCH_CHAT_ROOM)} replace />
                    } />
                    <Route path="/rep/:rep_name" element={
                        <Suspense fallback={<></>}>
                            <AgentProfile />
                        </Suspense>
                    } />
                    <Route path={`/${getRouteAfterRemovingPrefixAndPostfix(ROUTE_FETCH_INTERACTIVE_METRICS_ANALYTICS, ROUTE_FETCH_ROOT)}`} element={
                        isInOrgModeAndNotMemberRoleUser && currentUser?.ui_settings?.show_echo ?
                            <Suspense fallback={<></>}>
                                <FetchInteractiveMetrics selectedFilters={selectedFilters} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_ACCESS_DENIED)} replace />
                    } />
                    <Route path={`/${getRouteAfterRemovingPrefixAndPostfix(ROUTE_FETCH_KNOWLEDGE_GAP_REPORT, ROUTE_FETCH_ROOT)}`} element={
                        isInOrgModeAndNotMemberRoleUser ?
                            <Suspense fallback={<></>}>
                                <FetchKnowledgeGapReport />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_ACCESS_DENIED)} replace />
                    } />
                    <Route path={`/${getRouteAfterRemovingPrefixAndPostfix(ROUTE_FETCH_CONVERSATION_SUMMARY, ROUTE_FETCH_ROOT)}`} element={
                        isInOrgModeAndNotMemberRoleUser ?
                            <Suspense fallback={<></>}>
                                <FetchConversationSummary />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_ACCESS_DENIED)} replace />
                    } />

                    {/* TODO: remove hardcoded values */}
                    <Route path={`/${getRouteAfterRemovingPrefixAndPostfix(ROUTE_FETCH_CONVERSATIONS, ROUTE_FETCH_ROOT)}`} element={
                        isInOrgModeAndNotMemberRoleUser ?
                            <Suspense fallback={<></>}>
                                <FetchConversations selectedFilters={{
                                    "sentiments": [
                                        "negative",
                                        "neutral",
                                        "positive"
                                    ],
                                    "emotions": [
                                        "angry",
                                        "anxious",
                                        "calm",
                                        "confident",
                                        "confused",
                                        "curious",
                                        "desperate",
                                        "determined",
                                        "disappointed",
                                        "frustrated",
                                        "grateful",
                                        "hopeful",
                                        "indifferent",
                                        "No Emotion",
                                        "overwhelmed",
                                        "relieved",
                                        "sad",
                                        "satisfied",
                                        "suspicious"
                                    ],
                                    "purposes": [
                                        "Account Balance",
                                        "Account Balance and Transaction Inquiries",
                                        "Account balance inquiry",
                                        "Account Closure Requests",
                                        "Account Management",
                                        "Account Management;Adding/removing joint owners or beneficiaries",
                                        "Account Management;Closing accounts",
                                        "Account Management;Linking accounts",
                                        "Account Management;Opening accounts",
                                        "Account Management;Updating account information",
                                        "Account Opening Inquiries",
                                        "Analytics and Insights",
                                        "Analytics and Insights;Organization Analytics",
                                        "Analytics and Insights;Query History",
                                        "Analytics and Insights;Voice Trends",
                                        "ATM Services",
                                        "ATM Services;ATM deposits and withdrawals",
                                        "ATM Services;ATM locations and availability",
                                        "ATM Services;ATM troubleshooting and maintenance",
                                        "Bill Pay",
                                        "Branch Services and Locations",
                                        "Business Services",
                                        "Business Services;Business account opening and management",
                                        "Business Services;Business loans and credit cards",
                                        "Business Services;Merchant services",
                                        "Certificate of Deposit (CD) Information",
                                        "Certificates of Deposit (CDs) and IRAs",
                                        "Certificates of Deposit (CDs) and IRAs;CD and IRA rates and terms",
                                        "Certificates of Deposit (CDs) and IRAs;IRA contributions and withdrawals",
                                        "Certificates of Deposit (CDs) and IRAs;Opening and closing CDs and IRAs",
                                        "Certificates of Deposit (CDs) and IRAs;Required Minimum Distributions (RMDs)",
                                        "Check Deposit",
                                        "Checks and Monetary Instruments",
                                        "Checks and Monetary Instruments;Cashier's checks",
                                        "Checks and Monetary Instruments;Foreign checks",
                                        "Checks and Monetary Instruments;Money orders",
                                        "Checks and Monetary Instruments;Ordering checks",
                                        "Collections",
                                        "Collections;Auto Generate",
                                        "Collections;Create",
                                        "Collections;Share",
                                        "Compliance and Regulations",
                                        "Compliance and Regulations;Anti-Money Laundering (AML) policies",
                                        "Compliance and Regulations;Bank Secrecy Act (BSA) compliance",
                                        "Compliance and Regulations;Fair Lending and Equal Credit Opportunity Act (ECOA)",
                                        "Compliance and Regulations;Office of Foreign Assets Control (OFAC) compliance",
                                        "Compliance and Regulations;Truth in Lending Act (TILA) and Truth in Savings Act (TISA)",
                                        "Credit Cards",
                                        "Credit Cards;Balance transfers",
                                        "Credit Cards;Credit card applications",
                                        "Credit Cards;Credit card rates and fees",
                                        "Credit Cards;Credit card rewards and benefits",
                                        "Credit Cards;Credit card servicing",
                                        "Credit Report Queries",
                                        "Debit Cards",
                                        "Debit Cards;Debit card limits and fees",
                                        "Debit Cards;Debit card ordering and activation",
                                        "Debit Cards;Debit card PIN reset",
                                        "Debit Cards;Debit card transaction disputes",
                                        "Debit/Credit Card Issues",
                                        "Direct Deposit Setup",
                                        "Employee and Branch Information",
                                        "Employee and Branch Information;Branch information and hours",
                                        "Employee and Branch Information;Employee benefits and policies",
                                        "Employee and Branch Information;Employee directory and contact information",
                                        "Enrollment",
                                        "eStatements",
                                        "Financial Education Resources",
                                        "Financial Planning Services",
                                        "Fraud and Disputes",
                                        "Fraud and Disputes;Disputing transactions",
                                        "Fraud and Disputes;High-risk authentication",
                                        "Fraud and Disputes;MICR number changes due to fraud",
                                        "Fraud and Disputes;Reporting fraudulent activity",
                                        "Fraud and Security Concerns",
                                        "Insurance",
                                        "Insurance;Auto insurance",
                                        "Insurance;Credit life and disability insurance",
                                        "Insurance;GAP insurance",
                                        "Insurance;Homeowners insurance",
                                        "Interest Rates Information",
                                        "Internal Systems and Software",
                                        "Internal Systems and Software;Eltropy secure messaging platform",
                                        "Internal Systems and Software;Meridian Link loan origination software",
                                        "Internal Systems and Software;Symitar/Episys core banking system",
                                        "Internal Systems and Software;Synergy document management system",
                                        "Internal Systems and Software;Verafin fraud detection software",
                                        "Knowledge",
                                        "Knowledge;Add",
                                        "Knowledge;Associate",
                                        "Knowledge;Create",
                                        "Knowledge Management",
                                        "Knowledge Management;Collection Management",
                                        "Knowledge Management;Customer Conversation Ingestion",
                                        "Knowledge Management;Document Management",
                                        "Knowledge Management;Knowledge Base Modification",
                                        "Lending Policies and Procedures",
                                        "Lending Policies and Procedures;Adverse action notices and credit reporting",
                                        "Lending Policies and Procedures;Collateral requirements and valuation",
                                        "Lending Policies and Procedures;Debt-to-income (DTI) and loan-to-value (LTV) calculations",
                                        "Lending Policies and Procedures;Loan underwriting guidelines",
                                        "Loan Applications",
                                        "Loan Payment Assistance",
                                        "Loans",
                                        "Loans;GAP coverage",
                                        "Loans;Loan applications",
                                        "Loans;Loan payoff and lien release",
                                        "Loans;Loan rates and terms",
                                        "Loans;Loan servicing and payments",
                                        "Login",
                                        "Member Benefits Information",
                                        "Member Complaints and Feedback",
                                        "Member Services",
                                        "Member Services;Address and phone number changes",
                                        "Member Services;Complaint resolution and escalation",
                                        "Member Services;Membership eligibility and requirements",
                                        "Member Services;Name changes and corrections",
                                        "Merchant Services",
                                        "Merchant Services;Credit card processing",
                                        "Merchant Services;Merchant account setup and support",
                                        "Merchant Services;Point-of-sale (POS) transactions",
                                        "Miscellaneous",
                                        "Miscellaneous;Coin and currency orders",
                                        "Miscellaneous;Notary services",
                                        "Miscellaneous;Safe deposit boxes",
                                        "Miscellaneous;Scholarships and community events",
                                        "Mortgage Queries",
                                        "Online Banking",
                                        "Online Banking;Account Balance",
                                        "Online Banking Assistance",
                                        "Online Banking;Bill Pay",
                                        "Online Banking;Check Deposit",
                                        "Online Banking;Enrollment",
                                        "Online Banking;eStatements",
                                        "Online Banking;Login",
                                        "Online Banking;Transfers",
                                        "Overdraft and Fee Issues",
                                        "Overdraft Protection and Fees",
                                        "Overdraft Protection and Fees;Non-sufficient funds (NSF) fees",
                                        "Overdraft Protection and Fees;Overdraft fees and limits",
                                        "Overdraft Protection and Fees;Overdraft protection enrollment and qualifications",
                                        "Platform Features",
                                        "Platform Features;Builder",
                                        "Platform Features;Content Block",
                                        "Platform Features;Events",
                                        "Platform Features;Insight Generation",
                                        "Platform Features;One Chat",
                                        "Platform Features;Response Feedback and Comments",
                                        "Policies and Procedures",
                                        "Policies and Procedures;Account opening requirements and documentation",
                                        "Policies and Procedures;BSA/AML and OFAC compliance",
                                        "Policies and Procedures;Identification and verification procedures",
                                        "Policies and Procedures;Regulation D and other compliance matters",
                                        "Security and Fraud Prevention",
                                        "Security and Fraud Prevention;Fraud detection and prevention",
                                        "Security and Fraud Prevention;Identity verification and authentication",
                                        "Security and Fraud Prevention;Phishing and social engineering awareness",
                                        "Security and Fraud Prevention;Secure email and fax transmission",
                                        "Shared Branching",
                                        "Shared Branching;Processing shared branch transactions",
                                        "Shared Branching;Shared branching guidelines and procedures",
                                        "Statements and Documents",
                                        "Statements and Documents;Document retention policies",
                                        "Statements and Documents;Notices and disclosures",
                                        "Statements and Documents;Statement copies and history",
                                        "Statements and Documents;Tax forms (1099, 1098, etc.)",
                                        "Transactions",
                                        "Transactions;Check cashing",
                                        "Transactions;Deposits",
                                        "Transactions;Stop payments",
                                        "Transactions;Transfers",
                                        "Transactions;Withdrawals",
                                        "Transfers",
                                        "Trusts and Estate Planning",
                                        "Trusts and Estate Planning;Estate planning services",
                                        "Trusts and Estate Planning;Power of Attorney (POA) and guardianship",
                                        "Trusts and Estate Planning;Trust account opening and management",
                                        "User Management",
                                        "User Management;User Addition",
                                        "User Management;User Deletion",
                                        "User Management;User Role Modification",
                                        "User Roles and Permissions",
                                        "User Roles and Permissions;Org Collaborator",
                                        "User Roles and Permissions;Org Member",
                                        "Wire Transfers and Electronic Payments"
                                    ],
                                    "reps": [
                                        "Alexis King",
                                        "Alex Johnson",
                                        "Amit Bhanot",
                                        "Andy Tran",
                                        "Casey Lee",
                                        "Charlie Green",
                                        "Chris Smith",
                                        "Dylan Carter",
                                        "Farhan Rehman",
                                        "Jamie Brown",
                                        "Jordan Davis",
                                        "Mavis Wang",
                                        "Morgan White",
                                        "Pat Taylor",
                                        "Rahul Hingorani",
                                        "Saroop Bharwani",
                                        "Sebastian Rosado",
                                        "Senso",
                                        "Test Rep",
                                        "Thomas Nelson"
                                    ],
                                    "startDate": "2024-11-07",
                                    "endDate": "2025-02-08",
                                    "type": [
                                        "Call"
                                    ],
                                    "channels": [
                                        "Call Center",
                                        "Retail Ops"
                                    ],
                                    "custom_tags": [
                                        null
                                    ]
                                }} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_ACCESS_DENIED)} replace />
                    } />
                    <Route path="/knowledge" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchKnowledgeDocuments key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge/content/:document_id/edit" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchContentEdit key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/knowledge/content/create" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchContentCreate key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/tags" element={
                        isInOrgMode ?
                            <Suspense fallback={<></>}>
                                <FetchTags key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_PROFILE)} replace />
                    } />
                    <Route path="/collections" element={
                        hasCollectionViewPermissions ?
                            <Suspense fallback={<></>}>
                                <FetchCollections key={location.key} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_FETCH_CHAT_ROOM)} replace />
                    } />
                    <Route path="/collections/:collection_id/content/:content_id" element={
                        hasCollectionViewPermissions ?
                            <Suspense fallback={<></>}>
                                <FetchCollection pageMode={CollectionPageModeEnum.CONTENT} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_FETCH_CHAT_ROOM)} replace />
                    } />
                    <Route path={`/${getRouteAfterRemovingPrefixAndPostfix(ROUTE_FETCH_COLLECTION_OVERVIEW, ROUTE_FETCH_ROOT)}`} element={
                        hasCollectionViewPermissions ?
                            <Suspense fallback={<></>}>
                                <FetchCollection pageMode={CollectionPageModeEnum.OVERVIEW} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_FETCH_CHAT_ROOM)} replace />
                    } />
                    <Route path={`/${getRouteAfterRemovingPrefixAndPostfix(ROUTE_FETCH_COLLECTION_CRM, ROUTE_FETCH_ROOT)}`} element={
                        hasCollectionViewPermissions ?
                            <Suspense fallback={<></>}>
                                <FetchCollection pageMode={CollectionPageModeEnum.CRM} />
                            </Suspense>
                            :
                            <Navigate to={getRoute(ROUTE_FETCH_CHAT_ROOM)} replace />
                    } />
                    <Route path={`/${getRouteAfterRemovingPrefixAndPostfix(ROUTE_FETCH_COLLECTION_REPORT, ROUTE_FETCH_ROOT)}`} element={hasCollectionViewPermissions ?
                        <Suspense fallback={<></>}>
                            <FetchCollection pageMode={CollectionPageModeEnum.REPORT} />
                        </Suspense>
                        :
                        <Navigate to={getRoute(ROUTE_FETCH_CHAT_ROOM)} replace />
                    } />
                    <Route path="/rep/:rep_uuid" element={
                        <Suspense fallback={<></>}>
                            <AgentProfile />
                        </Suspense>
                    } />

                    <Route path="/collection-content/refresh" element={<Navigate to={getRoute(ROUTE_FETCH_COLLECTION_CONTENT, { collection_id: firstCollection?.collection_id ?? 'undefined', content_id: firstCollection?.first_document_id ?? 'undefined' })} replace />} />
                    <Route path="*" element={<Navigate replace to={isInOrgMode ? getRoute(ROUTE_FETCH_CHAT_ROOM) : getRoute(ROUTE_PROFILE)} key={location.key} />} />
                    <Route path={getRoute(ROUTE_FETCH_COLLECTION_CONTENT_REFRESH)}
                        element={<Navigate to={getRoute(ROUTE_FETCH_COLLECTION_CONTENT,
                            {
                                collection_id: firstCollection?.collection_id ?? 'undefined',
                                content_id: firstCollection?.first_document_id ?? 'undefined'
                            }
                        )}
                            replace />} />
                    <Route path="*" element={<Navigate replace to={getRoute(isInOrgMode ? ROUTE_FETCH_CHAT_ROOM : ROUTE_PROFILE)} key={location.key} />} />
                </Routes>
            </div>
        </>
    );
};

export default AgentFetch;